import "react-phone-number-input/style.css";

import React from "react";
import {
  NativeSyntheticEvent,
  StyleSheet,
  TextInput,
  TextInputKeyPressEventData,
} from "react-native";
import BasePhoneInput from "react-phone-number-input";
import { CountrySelectWithIcon } from "react-phone-number-input/modules/CountrySelect";
import styled from "styled-components/native";
import { E164Number } from "libphonenumber-js/types";

const styles = StyleSheet.create({
  phoneInput: {
    fontFamily: "Jost_500Medium",
    color: "#2E3338",
    paddingHorizontal: 24,
    paddingVertical: 13,
    borderColor: "#DDDDDD",
    borderWidth: 1,
    borderRadius: 100,
    outlineStyle: "none",
  },
});

const TextInputContainer = styled.View`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 12px;
`;

const TextInputLabel = styled.Text`
  font-family: "Jost_400Regular";
  color: #757f87;
  margin-bottom: 8px;
  margin-left: 10px;
`;

const InputComponent = React.forwardRef<HTMLInputElement, any>((props, ref) => (
  <TextInputContainer>
    <TextInputLabel>Phone Number</TextInputLabel>
    <TextInput style={styles.phoneInput} ref={ref} type="search" {...props} />
  </TextInputContainer>
));

const CountryInputContainer = styled.View`
  display: flex;
  flex-direction: column;
`;

const CountryInputLabel = styled.Text`
  font-family: "Jost_400Regular";
  color: #757f87;
  margin-bottom: 8px;
  margin-left: 10px;
`;

const CountrySelectContainer = styled.View`
  border-radius: 100px;
  border: 1px solid #dddddd;
  padding: 15px 24px;
`;

const CountrySelectComponent = React.forwardRef<HTMLSelectElement, any>((props, ref) => (
  <CountryInputContainer>
    <CountryInputLabel>Country</CountryInputLabel>
    <CountrySelectContainer>
      <CountrySelectWithIcon ref={ref} {...props} />
    </CountrySelectContainer>
  </CountryInputContainer>
));

const ContainerComponent = styled.View`
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
`;

interface PhoneInputProps {
  value: E164Number;
  onChange: (value: E164Number) => void;
  onKeyPress?: (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => void;
}

const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, value, onKeyPress = () => {} }) => {
  return (
    <BasePhoneInput
      international
      placeholder="+1 (123) 456 7890"
      value={value}
      onChange={(newPhoneNumber) => onChange(newPhoneNumber!)}
      inputComponent={InputComponent}
      containerComponent={ContainerComponent}
      countrySelectComponent={CountrySelectComponent}
      numberInputProps={{ onKeyPress }}
    />
  );
};

export default PhoneInput;
