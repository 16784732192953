import React, { useState } from "react";
import { LayoutChangeEvent, ScrollView, View, ViewProps } from "react-native";
import { useRoute } from "@react-navigation/native";
import { MenuProvider } from "react-native-popup-menu";
import { Linking } from "expo";
import styled from "rn-css";
import RouteParamsContext, { RouteParams } from "../components/contexts/RouteParamsContext";
import ShareContext from "../components/contexts/ShareContext";
import SafeArea from "../components/SafeArea";
import PageFooter from "../components/PageFooter";
import TopNavigationResponsive from "../components/TopNavigationResponsive";
import LoadingOverlay from "../components/LoadingOverlay";
import Modal from "../components/Modal";
import useListener from "../hooks/useListener";
import { FlexCol } from "../styles/containers";
import ShareDialog from "../components/ShareDialog";
import share from "../utils/share";

const Container = styled(ScrollView)`
  display: flex;
  flex-direction: column;
  align-content: stretch;
`;

interface BodyProps extends ViewProps {
  headerHeight: number;
  footerHeight: number;
  alignStretch?: boolean;
}

const Body = styled(View)<BodyProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignStretch }) => (alignStretch ? "stretch" : "center")};
  min-height: calc(100vh - (${({ headerHeight, footerHeight }) => headerHeight + footerHeight}px));
`;

const ShrinkBody = styled(View)<BodyProps>`
  display: flex;
  flex-direction: column;
  align-items: ${({ alignStretch }) => (alignStretch ? "stretch" : "center")};
`;

export interface MainLayoutProps extends ViewProps {
  alignStretch?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  modalDialog?: React.ReactNode;
  showModalDialog?: boolean;
  onModalBackdropPress?: () => void;
  loading?: boolean;
  loadingText?: string;
  onlyBody?: boolean;
  showFooter?: boolean;
  shareTitle?: string;
  shareQrCode?: string;
  useShareDialog?: boolean;
}

const MainLayout: React.FC<MainLayoutProps> = ({
  children,
  alignStretch = true,
  header,
  footer,
  modalDialog,
  showModalDialog = false,
  onModalBackdropPress,
  loading = false,
  loadingText = "",
  onlyBody = false,
  showFooter = true,
  shareTitle = "",
  shareQrCode = "",
  useShareDialog = false,
  ...props
}) => {
  const route = useRoute();
  const routeParams: RouteParams = route.params || {};

  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const [footerHeight, setFooterHeight] = useState<number>(0);

  const [linkCopied, setLinkCopied] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);

  const onHeaderLayout = useListener((e: LayoutChangeEvent) => {
    if (onlyBody) return;

    const {
      nativeEvent: {
        layout: { height },
      },
    } = e;

    setHeaderHeight(height);
  });

  const onFooterLayout = useListener((e: LayoutChangeEvent) => {
    if (onlyBody) return;

    const {
      nativeEvent: {
        layout: { height },
      },
    } = e;

    setFooterHeight(height);
  });

  const onShareOptionPress = useListener(
    (id: string | number, value: string, isCampaign = true) => {
      const shareUrls = isCampaign ? share(id).campaign : share(id).fundraiser;

      if (value in shareUrls) {
        Linking.openURL(shareUrls[value]);
        return;
      }

      switch (value) {
        case "qrcode":
          setQrCodeModalVisible(true);
          break;

        case "link":
          navigator.clipboard.writeText(window.location.href).then(() => setLinkCopied(true));
          break;

        default:
          break;
      }
    }
  );

  return (
    <MenuProvider skipInstanceCheck>
      <RouteParamsContext.Provider value={routeParams}>
        <ShareContext.Provider value={{ setQrCodeModalVisible, onShareOptionPress, linkCopied }}>
          <SafeArea>
            {!onlyBody ? (
              <>
                <FlexCol onLayout={onHeaderLayout}>
                  <TopNavigationResponsive />
                  {header}
                </FlexCol>
                <Container>
                  <Body
                    headerHeight={headerHeight}
                    footerHeight={footerHeight}
                    alignStretch={alignStretch}
                    {...props}
                  >
                    {children}
                  </Body>
                  {footer}
                  {showFooter && (
                    <FlexCol onLayout={onFooterLayout}>
                      <PageFooter />
                    </FlexCol>
                  )}
                </Container>
              </>
            ) : (
              <ShrinkBody headerHeight={0} footerHeight={0} alignStretch={alignStretch} {...props}>
                {children}
              </ShrinkBody>
            )}

            {modalDialog && (
              <Modal visible={showModalDialog} onBackdropPress={onModalBackdropPress}>
                {modalDialog}
              </Modal>
            )}
            {useShareDialog && (
              <Modal
                visible={qrCodeModalVisible}
                onBackdropPress={() => setQrCodeModalVisible(false)}
              >
                <ShareDialog
                  title={shareTitle}
                  qrCode={shareQrCode}
                  onClose={() => setQrCodeModalVisible(false)}
                />
              </Modal>
            )}
            <LoadingOverlay visible={loading} text={loadingText} />
          </SafeArea>
        </ShareContext.Provider>
      </RouteParamsContext.Provider>
    </MenuProvider>
  );
};

export default MainLayout;
