import React from "react";
import {
  IndexPath,
  Menu as MenuBase,
  MenuItem as MenuItemBase,
  Popover,
  PopoverProps,
  Text,
} from "@ui-kitten/components";
import styled from "rn-css";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import useListener from "../hooks/useListener";

const styles = {
  menuItem: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 21,
    paddingRight: 21,
  },
};

const Header = styled(View)`
  padding: 12px 12px 8px 16px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Menu = styled(MenuBase)`
  width: max-content;
  background: transparent;
  overflow: hidden;
`;

const MenuItem = styled(MenuItemBase)`
  min-width: 200px;
  align-items: center;
  border-left-width: 4px;
  border-left-color: transparent;

  &:hover {
    border-left-color: #3488ec;
    background-color: #eaf3fc;
  }
`;

const MenuText = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 20px;
  }
`;

const Container = styled(Popover)`
  gap: 2px;
  padding: 0px;
  width: max-content;
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  overflow: hidden;
`;

export interface MenuOption {
  key: string;
  name: string;
  onSelect?: (key?: string) => void;
}

export interface MenuOptionsProps extends PopoverProps {
  title?: string;
  options: MenuOption[];
  onSelect?: (key?: string) => void;
  styleTree?: {
    container?: any;
    header?: any;
    heading?: any;
    options?: any;
    optionText?: any;
    optionItem?: any;
  };
}

const MenuOptions: React.FC<MenuOptionsProps> = ({
  title,
  options,
  onSelect,
  style,
  styleTree,
  ...props
}) => {
  const onSelectIndex = useListener((indexPath: IndexPath) => {
    const { key } = options.at(indexPath.row) ?? {};
    if (key) {
      onSelect?.(key);
      options[indexPath.row].onSelect?.(key);
    }
  });

  return (
    <Container style={[style, styleTree?.container]} {...props}>
      <>
        {title && (
          <Header style={styleTree?.header}>
            <Heading style={styleTree?.heading}>{title}</Heading>
          </Header>
        )}
        <Menu appearance="noDivider" onSelect={onSelectIndex} style={styleTree?.options}>
          {options.map(({ key, name }) => (
            <MenuItem
              key={key}
              title={() => <MenuText style={styleTree?.optionText}>{name}</MenuText>}
              style={[styles.menuItem, styleTree?.optionItem]}
            />
          ))}
        </Menu>
      </>
    </Container>
  );
};

export default MenuOptions;
