import { useEffect, useState } from "react";
import { useStore } from "../store";
import Toast, { SHORT } from "../components/Toast";
import Campaign from "../store/models/Campaign";
import { APICampaignListSearchParams } from "../api/campaigns";

export interface FetchCampaignInputData {
  page?: number;
  limit?: number;
  searchParams?: APICampaignListSearchParams;
}

export interface FetchCampaignResultsCleanedData {
  count: number;
  previous: boolean;
  next: boolean;
  results: Campaign[];
  loading: boolean;
  error: any;
}

const useCampaignList = ({ page = 1, limit, searchParams }: FetchCampaignInputData = {}) => {
  const { campaigns, me } = useStore();
  const isLoggedIn = !!me;
  const [key, setKey] = useState(JSON.stringify({ ...searchParams, isLoggedIn }));
  const [fetchResult, setFetchResult] = useState<FetchCampaignResultsCleanedData>({
    count: 0,
    previous: false,
    next: false,
    results: [],
    loading: false,
    error: undefined,
  });

  useEffect(() => {
    setFetchResult({
      ...fetchResult,
      loading: true,
      error: undefined,
    });
    if (searchParams) {
      campaigns
        .list(page, limit, searchParams)
        .then(({ count, previous, next, results }) => {
          let newResults: Campaign[] = [];
          if (JSON.stringify({ ...searchParams, isLoggedIn }) === key && page > 1) {
            newResults = [...fetchResult.results, ...(results as Campaign[])];
          } else {
            newResults = [...(results as Campaign[])];
          }
          setFetchResult({
            count,
            previous,
            next,
            results: newResults,
            loading: false,
            error: undefined,
          });
          setKey(JSON.stringify({ ...searchParams, isLoggedIn }));
        })
        .catch((e) => {
          setFetchResult({
            count: 0,
            previous: false,
            next: false,
            results: [],
            loading: false,
            error: e,
          });
          Toast.show({
            type: "error",
            text1: "Error Fetching Campaigns",
            visibilityTime: SHORT,
          });
        });
    } else {
      setFetchResult({
        count: 0,
        previous: false,
        next: false,
        results: [],
        loading: false,
        error: undefined,
      });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [campaigns, page, limit, searchParams, isLoggedIn]);

  return fetchResult;
};

export default useCampaignList;
