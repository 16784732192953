import React from "react";
// import { Subscription } from "@unimodules/core";
import { enableScreens } from "react-native-screens";
import * as Sentry from "sentry-expo";
// import * as Notifications from "expo-notifications";
import AppLoading from "expo-app-loading";
import api from "./src/api";
import appConfig from "./src/configs/appConfig";
import { useInit } from "./src/hooks/useInit";
import Root from "./src/screens/Root";
import { StoreProvider } from "./src/store";
// import { registerForPushNotificationsAsync } from "./src/utils/notification";
import "./app.css";

/*
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: true,
    shouldPlaySound: false,
    shouldSetBadge: false,
  }),
});
*/

interface Props {}

enableScreens();

api.config.baseUrl = appConfig.apiBaseUrl! as string;
api.config.defaultLimit = appConfig.apiDefaultLimit as number;
api.config.timeout = appConfig.apiTimeout as number;

const sentryDsn = appConfig.sentryDsn as string;
const dev = !!appConfig.isLocal;

if (sentryDsn) {
  const environment = appConfig.environment as string;
  Sentry.init({
    dsn: sentryDsn,
    enableInExpoDevelopment: true,
    environment,
    debug: dev,
  });
}

const App: React.FC<Props> = () => {
  const { ready, store } = useInit();
  /*
  const notificationListener = useRef<Subscription | null>(null);
  const responseListener = useRef<Subscription | null>(null);

  useEffect(() => {
    registerForPushNotificationsAsync().then((token) => {
      if (ready && !!store && !store.deviceToken) {
        store.setDeviceToken(token);
      }
    });

    // This listener is fired whenever a notification is received while the app is foregrounded
    notificationListener.current = Notifications.addNotificationReceivedListener((notif) => {
      console.log(notif);
    });

    // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
    responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
      console.log(response);
    });

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current as Subscription);
      Notifications.removeNotificationSubscription(responseListener.current as Subscription);
    };
  }, [ready, store]);
  */

  if (!ready) {
    return <AppLoading />;
  }

  return (
    <StoreProvider value={store!}>
      <Root />
    </StoreProvider>
  );
};

export default App;
