import React, { useContext, useState } from "react";
import { LayoutChangeEvent, StyleSheet, ViewProps } from "react-native";
import styled from "rn-css";
import { observer } from "mobx-react-lite";
import { clamp } from "lodash";
import { useNavigation } from "@react-navigation/native";
import useListener from "../hooks/useListener";
import Campaign from "../store/models/Campaign";
import { FlexCol } from "../styles/containers";
import CampaignPreviewCardBase from "./CampaignPreviewCard";
import CarouselBase from "./Carousel";
import PaginationBase from "./Pagination";
import ShareContext from "./contexts/ShareContext";

const CARD_MIN_WIDTH = 400;
const CARD_MAX_WIDTH = 520;
const CARDS_GAP = 24;

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#cdced0",
  },
  activePage: {
    backgroundColor: "#acadaf",
  },
  carousel: {
    paddingVertical: 16,
  },
});

const CampaignPreviewCard = styled(CampaignPreviewCardBase)`
  width: 100%;
  min-width: ${CARD_MIN_WIDTH};
  max-width: ${CARD_MAX_WIDTH};

  @media (max-width: 768px) {
    min-width: none;
  }
`;

const Carousel = styled(CarouselBase)<{ width?: number }>`
  width: 100%;
  ${({ width }) => (width ? `max-width: ${width};` : "")}
`;

const CardWrapper = styled(FlexCol)`
  justify-items: center;
  align-items: center;
  padding: 0px 12px;
  flex-grow: 1;
`;

const Pagination = styled(PaginationBase)`
  gap: 12px;
`;

const Container = styled(FlexCol)`
  justify-content: center;
  gap: 48px;
  background-color: #ffffff;
  flex-grow: 0;

  @media (max-width: 768px) {
    gap: 24px;
    align-items: center;
  }
`;

export interface FeaturedCampaignsCarouselProps extends Omit<ViewProps, "children"> {
  featuredCampaigns: Campaign[];
  setCampaign?: React.Dispatch<React.SetStateAction<Campaign | undefined>>;
}

const FeaturedCampaignsCarousel: React.FC<FeaturedCampaignsCarouselProps> = ({
  featuredCampaigns,
  setCampaign,
  ...props
}) => {
  const navigation = useNavigation();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [viewItemCount, setViewItemCount] = useState(1);
  const [carouselWidth, setCarouselWidth] = useState<number>();
  const { onShareOptionPress } = useContext(ShareContext);

  const onCampaignManagePress = useListener((campaign) => {
    navigation.navigate("CampaignEdit", { id: campaign.id });
  });

  const onSelectIndex = useListener((index: number) => {
    setSelectedIndex(index);
  });

  const onContainerLayout = useListener((e: LayoutChangeEvent) => {
    const {
      nativeEvent: {
        layout: { width },
      },
    } = e;

    const viewCount = clamp(
      Math.floor(width / (CARD_MIN_WIDTH + CARDS_GAP)),
      1,
      featuredCampaigns.length
    );
    setViewItemCount(viewCount);
    const cWidth = viewCount * (CARD_MAX_WIDTH + CARDS_GAP);
    setCarouselWidth(cWidth);
  });

  const pageCount = featuredCampaigns.length - viewItemCount + 1;

  return (
    <Container onLayout={onContainerLayout} {...props}>
      <Carousel
        width={carouselWidth}
        viewItemCount={viewItemCount}
        selectedIndex={selectedIndex}
        onSelectIndex={onSelectIndex}
        customStyles={styles}
        {...props}
      >
        {featuredCampaigns.map((campaign) => (
          <CardWrapper key={campaign.id}>
            <CampaignPreviewCard
              campaign={campaign}
              onManagePress={onCampaignManagePress}
              onSharePress={() => {
                if (setCampaign) {
                  setCampaign(campaign!);
                }
                onShareOptionPress(campaign.id!, "qrcode");
              }}
            />
          </CardWrapper>
        ))}
      </Carousel>
      {pageCount > 1 && (
        <Pagination
          count={pageCount}
          selectedIndex={selectedIndex}
          onSelectIndex={onSelectIndex}
          pageStyle={styles.page}
          activePageStyle={styles.activePage}
        />
      )}
    </Container>
  );
};

export default observer(FeaturedCampaignsCarousel);
