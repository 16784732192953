import {
  Layout,
  Text,
  TopNavigation,
  Button,
  Icon,
  Divider,
  Avatar as UIKAvatar,
  Popover as UIKPopover,
  Drawer,
  DrawerItem,
  EvaProp,
  useTheme,
} from "@ui-kitten/components";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { StyleSheet, TouchableOpacity } from "react-native";
import { useNavigation, useRoute } from "@react-navigation/native";
import styled from "rn-css";
import { useIsDrawerOpen } from "@react-navigation/drawer";
import { observer } from "mobx-react-lite";
import Logo from "./svgs/Logo";
import LogoWithText from "./svgs/LogoWithText";
import { useStore } from "../store";
import useWindowSize from "../hooks/useWindowSize";
import RoundedButton from "./Button";
import VerticalDivider from "./VerticalDivider";
// import Envelope from "./svgs/Envelope";
// import Bell from "./svgs/Bell";
import ProfileChip from "./svgs/ProfileChip";
import SearchBar from "./forms/SearchBar";
import WalletConnectContext from "./contexts/WalletConnectContext";

const styles = StyleSheet.create({
  topNavigationNarrow: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 80,
  },
  topNavigationWide: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingHorizontal: 0,
    paddingVertical: 0,
    height: 80,
  },
  accessoryLeft: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    columnGap: 20,
    padding: 0,
    marginLeft: 40,
  },
  accessoryRight: {
    flex: 1,
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "row",
    columnGap: 20,
    marginRight: 40,
  },
  logo: {
    height: 28,
    width: 28,
  },
  logoWithText: {
    height: 31.25,
    width: 85.3,
  },
  link: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
  },
  button: {
    paddingLeft: 20.5,
    paddingRight: 20.5,
    height: 40,
  },
  ghostButton: {
    height: 40,
  },
  buttonText: {
    fontFamily: "Jost_500Medium",
    fontSize: 15,
    color: "white",
  },
  icon: {
    width: 24,
    height: 24,
  },
  menuBurger: {
    color: "#2E3338",
    width: 32,
    height: 32,
    marginRight: 20,
  },
  searchBar: {
    marginVertical: 0,
    marginHorizontal: 0,
    backgroundColor: "none",
    border: 0,
  },
  searchBarContainer: {
    height: 38,
    width: 200,
    flexGrow: 1,
  },
  searchBarText: {
    fontFamily: "Jost_400Regular",
    fontSize: 16,
  },
  searchIcon: {
    width: 20,
    height: 20,
  },
  searchIconWrapper: {
    marginRight: 12,
  },
  activeSearchWrapper: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 40,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  popover: {
    borderRadius: 10,
    width: 147,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: "#EDEEEF",
    boxShadow: "0px 1px 4px 0px #00000026",
  },
  popoverDrawer: {
    borderRadius: 10,
  },
  popoverDrawerItem: {
    height: 46,
    paddingHorizontal: 16,
    paddingVertical: 12,
  },
  popoverDrawerItemText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "#2E3338",
    textAlign: "right",
    width: "100%",
  },
  popoverDrawerItemTextHighlight: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "#27A563",
    textAlign: "right",
    width: "100%",
  },
});

const ROUTE_NAMES_WITHOUT_SEARCH = ["Categories", "CampaignCategoryExplore"];

const TopStickyLayout = styled(Layout)`
  position: sticky;
  top: 0;
  z-index: 1;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.04));
`;

const logoWithTextDimensions = StyleSheet.flatten([styles.logoWithText]);
const logoDimensions = StyleSheet.flatten([styles.logo]);
// const iconDimensions = StyleSheet.flatten([styles.icon]);
const avatarDimensions = StyleSheet.flatten([styles.avatar]);

// Component: wide-screen-layout top navigation bar
const TopNavigationWide = observer((eva: EvaProp) => {
  const navigation = useNavigation();
  const route = useRoute();
  const store = useStore();
  const { disconnectWallet } = useContext(WalletConnectContext);
  const { searchPattern = "" } = (route.params || {}) as { searchPattern: string };
  const routeName = route.name;
  const hideSearch = ROUTE_NAMES_WITHOUT_SEARCH.includes(routeName);

  const theme = useTheme();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState<boolean>(false);

  const handleHome = useCallback(() => {
    navigation.navigate("Home");
  }, [navigation]);

  const handleCampaigns = useCallback(() => {
    navigation.navigate("Categories");
  }, [navigation]);

  const handleFAQ = useCallback(() => {
    navigation.navigate("FAQ");
  }, [navigation]);

  const handleLogin = useCallback(() => {
    if (!store.me) {
      navigation.navigate("SignIn", { next: "dashboard" });
    }
  }, [navigation, store.me]);

  const handleStartCampaign = useCallback(() => {
    if (store.me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  }, [navigation, store.me]);

  /* Search bar handlers */

  const handleSearchButton = useCallback(() => {
    setIsSearchActive(true);
  }, []);

  // Handle submitting a search query
  const handleSearchSubmit = useCallback(
    (submittedPattern: string) => {
      navigation.navigate("CampaignCategoryExplore", {
        categoryName: "all",
        searchPattern: submittedPattern,
      });
      setIsSearchActive(false);
    },
    [navigation, setIsSearchActive]
  );

  const handleSearchClear = () => {
    setIsSearchActive(false);
  };

  /* End search bar handlers */

  const handleMyCampaigns = useCallback(() => {
    if (store.me) {
      navigation.navigate("Dashboard");
    }
  }, [navigation, store.me]);

  /*
  const handleMessages = useCallback(() => {
    // TODO: Handle pressing messages button
  }, []);

  const handleNotifications = useCallback(() => {
    // TODO: Handle pressing notifications button
  }, []);
  */

  // TODO: Fix the image blinking for a split second
  // Render the actual avatar image
  const renderAvatar = () => {
    return (
      <TouchableOpacity onPress={() => setPopoverVisible(true)}>
        {store.me?.photo ? (
          <UIKAvatar
            source={{
              uri: store.me?.photoSizes?.medium || store.me?.photoSizes?.big,
              cache: "force-cache",
            }}
            style={styles.avatar}
          />
        ) : (
          <ProfileChip width={avatarDimensions.width} height={avatarDimensions.height} />
        )}
      </TouchableOpacity>
    );
  };

  // Popover menu actions
  const popoverItemActions: (() => void)[] = [
    () => {
      navigation.navigate("FundraiserDetail", { id: store.me?.id });
    },
    () => {
      navigation.navigate("Settings");
    },
    async () => {
      // TODO: Show some feedback while the app is logging the user out
      // it's too abrupt
      await disconnectWallet();
      store.logout();
      navigation.navigate("SignIn");
    },
  ];

  const renderLogoButton = useCallback(
    () => (
      <RoundedButton onPress={handleHome} type="greenGhost">
        {() => (
          <LogoWithText
            height={logoWithTextDimensions.height}
            width={logoWithTextDimensions.width}
          />
        )}
      </RoundedButton>
    ),
    [handleHome]
  );

  // Popover menu when pressing the avatar
  const AvatarPopover = (
    <UIKPopover
      visible={popoverVisible}
      anchor={renderAvatar}
      onBackdropPress={() => {
        setPopoverVisible(false);
      }}
      placement="bottom end"
      style={styles.popover}
    >
      <Drawer
        style={styles.popoverDrawer}
        onSelect={(index) => {
          popoverItemActions[index.row]();
          setPopoverVisible(false);
        }}
      >
        <DrawerItem
          title={() => <Text style={styles.popoverDrawerItemText}>My Profile</Text>}
          style={styles.popoverDrawerItem}
        />
        <DrawerItem
          title={() => <Text style={styles.popoverDrawerItemText}>Settings</Text>}
          style={styles.popoverDrawerItem}
        />
        <DrawerItem
          title={() => <Text style={styles.popoverDrawerItemTextHighlight}>Logout</Text>}
          style={styles.popoverDrawerItem}
        />
      </Drawer>
    </UIKPopover>
  );

  // left side of navbar
  const accessoryLeft = useCallback(() => {
    return (
      <Layout style={styles.accessoryLeft}>
        {renderLogoButton()}
        <RoundedButton
          text="Home"
          onPress={handleHome}
          type="link"
          containerStyle={styles.ghostButton}
        />
        <RoundedButton
          text="Campaigns"
          onPress={handleCampaigns}
          type="link"
          containerStyle={styles.ghostButton}
        />
        <RoundedButton
          text="FAQ"
          onPress={handleFAQ}
          type="link"
          containerStyle={styles.ghostButton}
        />
      </Layout>
    );
  }, [handleCampaigns, handleFAQ, handleHome, renderLogoButton]);

  // right side of navbar
  const accessoryRight = useCallback(() => {
    return (
      <Layout style={styles.accessoryRight}>
        {/* Search button */}
        {!hideSearch && (
          <>
            <RoundedButton
              onPress={handleSearchButton}
              type="link"
              containerStyle={styles.ghostButton}
            >
              {(evaProps) => (
                <>
                  <Layout style={styles.searchIconWrapper}>
                    <Icon
                      fill={theme!["text-hint-color"]}
                      name="search"
                      style={styles.searchIcon}
                    />
                  </Layout>
                  <Text {...evaProps} style={styles.link}>
                    Search
                  </Text>
                </>
              )}
            </RoundedButton>
            <VerticalDivider width={1} height={20} space={0} color="#dddddd" />
          </>
        )}
        {store.me ? (
          // Logged in
          <>
            <RoundedButton
              text="My Campaigns"
              onPress={handleMyCampaigns}
              type="link"
              containerStyle={styles.ghostButton}
            />
            {/*
            <TouchableOpacity onPress={handleMessages}>
              <Envelope width={iconDimensions.width} height={iconDimensions.height} />
            </TouchableOpacity>
            <TouchableOpacity onPress={handleNotifications}>
              <Bell width={iconDimensions.width} height={iconDimensions.height} />
            </TouchableOpacity>
            */}
            {AvatarPopover}
          </>
        ) : (
          // Not logged in
          <>
            <RoundedButton
              text="Login"
              onPress={handleLogin}
              type="link"
              containerStyle={styles.ghostButton}
            />
            <RoundedButton
              onPress={() => handleStartCampaign()}
              status="basic"
              containerStyle={styles.button}
            >
              {(evaProps) => (
                <Text {...evaProps} style={styles.buttonText}>
                  Start a Campaign
                </Text>
              )}
            </RoundedButton>
          </>
        )}
      </Layout>
    );
  }, [
    AvatarPopover,
    handleLogin,
    // handleMessages,
    handleMyCampaigns,
    // handleNotifications,
    handleSearchButton,
    handleStartCampaign,
    store.me,
    theme,
    hideSearch,
  ]);

  // render nav bar
  const renderTopNavigationWide = useCallback(
    () =>
      !hideSearch && isSearchActive ? (
        <TopNavigation
          title={() => (
            <Layout style={styles.activeSearchWrapper}>
              {renderLogoButton()}
              {/* Search bar */}
              <SearchBar
                {...eva}
                placeholder="Search for campaigns"
                onClear={handleSearchClear}
                onSubmit={handleSearchSubmit}
                style={styles.searchBar}
                containerStyle={styles.searchBarContainer}
                textStyle={styles.searchBarText}
                defaultValue={searchPattern}
                key={searchPattern}
              />
            </Layout>
          )}
          alignment="center"
          style={styles.topNavigationWide}
        />
      ) : (
        <TopNavigation
          accessoryLeft={accessoryLeft}
          accessoryRight={accessoryRight}
          alignment="center"
          style={styles.topNavigationWide}
        />
      ),
    [
      accessoryLeft,
      accessoryRight,
      eva,
      handleSearchSubmit,
      isSearchActive,
      renderLogoButton,
      searchPattern,
      hideSearch,
    ]
  );

  return <>{renderTopNavigationWide()}</>;
});

// Component: narrow-screen-layout top navigation bar
const TopNavigationNarrow = () => {
  const navigation = useNavigation();

  const handleMenuPress = useCallback(() => {
    // @ts-expect-error
    navigation.openDrawer();
  }, [navigation]);

  const accessoryRight = useCallback(() => {
    return (
      <TouchableOpacity onPress={handleMenuPress}>
        <Icon name="menu" style={styles.menuBurger} />
      </TouchableOpacity>
    );
  }, [handleMenuPress]);

  return (
    <TopNavigation
      title={() => <Logo width={logoDimensions.width} height={logoDimensions.height} />}
      accessoryRight={accessoryRight}
      alignment="center"
      style={styles.topNavigationNarrow}
    />
  );
};

// Component (exported): top navigation bar
const TopNavigationResponsive = () => {
  const { isTablet } = useWindowSize();
  const navigation = useNavigation();
  const isDrawerOpen = useIsDrawerOpen();

  // close drawer if, after resizing larger, it's still open
  useEffect(() => {
    if (!isTablet && isDrawerOpen) {
      // @ts-expect-error
      navigation.closeDrawer();
    }
  }, [isDrawerOpen, isTablet, navigation]);

  return (
    <TopStickyLayout>
      {isTablet ? <TopNavigationNarrow /> : <TopNavigationWide />}
      <Divider />
    </TopStickyLayout>
  );
};

export default observer(TopNavigationResponsive);
