import { model, Model, prop, modelAction } from "mobx-keystone";
import { computed } from "mobx";
import { APIUser, APIUserInput } from "../../api/users";

@model("doni/User")
class User extends Model({
  id: prop<number>(),
  phoneNumber: prop<string>(),
  address: prop<string>(),
  isActive: prop<boolean>(),
  isStaff: prop<boolean>(),
  name: prop<string>(),
  location: prop(""),
  bio: prop(""),
  photo: prop<string | null>(() => null),
  timezone: prop<string>(),
  photoSizes: prop<{ big?: string; medium?: string }>(() => ({})),
  facebook: prop<string | null>(() => null),
  twitter: prop<string | null>(() => null),
  linkedin: prop<string | null>(() => null),
  valoraQrCode: prop<string | null>(() => null),
}) {
  @modelAction
  update(apiData: APIUser) {
    this.isActive = apiData.isActive;
    this.isStaff = apiData.isStaff;
    this.name = apiData.name;
    this.location = apiData.location;
    this.bio = apiData.bio;
    this.photo = apiData.photo;
    this.timezone = apiData.timezone;
    this.photoSizes = apiData.photoSizes;
    this.facebook = apiData.facebook;
    this.valoraQrCode = apiData.valoraQrCode;
  }

  @computed
  get asAPI(): APIUserInput {
    return {
      name: this.name,
      location: this.location,
      bio: this.bio,
      photo: this.photo,
      timezone: this.timezone,
      facebook: this.facebook || undefined,
      twitter: this.twitter || undefined,
      linkedin: this.linkedin || undefined,
    };
  }
}

export default User;
