import React, { useState } from "react";
import { useNavigation } from "@react-navigation/native";
import { StyleSheet, View } from "react-native";
import { Text as TextBase } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import styled from "rn-css";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import { APICampaignListSearchParams } from "../api/campaigns";
import Campaign from "../store/models/Campaign";
import LogoGray from "../components/svgs/LogoGray";
import Button from "../components/Button";
import { FlexCol, FlexRow, placeCenter } from "../styles/containers";
import useListener from "../hooks/useListener";
import NoCampaign from "../components/svgs/NoCampaign";
import useWindowSize from "../hooks/useWindowSize";
import useUserCampaignList from "../hooks/useUserCampaignList";
import { responsive } from "../utils/responsive";
import CampaignGallery from "../components/CampaignGallery";
import { useStore } from "../store";
import CampaignFilterBar, { CampaignFilter } from "../components/CampaignFilterBar";
import TextWithHighlights from "../components/TextWithHighlights";

const styles = StyleSheet.create({
  button: {
    width: 240,
    height: 40,
  },
  buttonMobile: {
    width: "100%",
    maxWidth: 343,
    height: 40,
  },
  noCampaignButton: {
    width: 343,
    height: 40,
  },
  lightBlueText: {
    color: "#3488ec",
  },
  loadMore: {
    width: 240,
    alignSelf: "center",
    height: 40,
  },
  loadMoreMobile: {
    width: "100%",
  },
});

const highlightedTextStyles = StyleSheet.create({
  highlights: {
    color: "#3488ec",
  },
});

const MainLayout = styled(MainLayoutBase)<{ isMobile: boolean }>`
  padding: ${responsive("24px 20px", "36px 128px")};
  gap: ${responsive(16, 36)}px;
`;

const Header = styled(View)`
  display: flex;
  flex-direction: ${responsive("column", "row")};
  align-items: stretch;
  justify-content: space-between;
  gap: 8px;
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Heading = styled(Text)<{ isMobile: boolean }>`
  ${responsive(
    `
    font-family: "Jost_500Medium";
    font-weight: 500;
    font-size: 20px;
    line-height: 29px;
    color: "#2e3338";
    `,
    `
    font-family: "Jost_400Regular";
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: "#757f87";
    `
  )}
`;

const Info = styled(FlexRow)`
  align-items: center;
  gap: 16px;
`;

const EmptyGallery = styled(FlexCol)`
  align-items: center;
  padding: 0px 64px 64px;
  gap: 48px;
`;

const NoCampaignText = styled(Text)<{ isMobile: boolean }>`
  ${responsive(
    `
    max-width: 335px;
    font-size: 20px;
    line-height: 29px;
    `,
    `
    max-width: 540px;
    font-size: 36px;
    line-height: 52px;
    `
  )}
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const LogoContainer = styled(FlexRow)`
  justify-content: center;
  padding: 24px;
`;

const StickyFooter = styled(View)`
  z-index: 10;
  position: sticky;
  bottom: 0px;
  width: 100%;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
`;

interface Props extends MainLayoutProps {}

const Dashboard: React.FC<Props> = () => {
  const navigation = useNavigation();
  const { me } = useStore();
  const { isMobile } = useWindowSize();
  const [page, setPage] = useState(1);
  const [searchParams, setSearchParams] = useState<APICampaignListSearchParams>({});
  const { results: filteredCampaigns, loading, next, count } = useUserCampaignList({
    page,
    searchParams,
  });
  const [contextCampaign, setContextCampaign] = useState<Campaign>();
  const [filter, setFilter] = useState("all");

  const onFilterSelect = useListener((value) => {
    setPage(1);
    setFilter(value);

    switch (value) {
      case "all":
        setSearchParams({});
        break;

      case "active":
        setSearchParams({ is_draft: false });
        break;

      case "draft":
        setSearchParams({ is_draft: true });
        break;

      default:
        break;
    }
  });

  const [filterItems] = useState<CampaignFilter[]>([
    { key: "all", name: "All", onSelect: () => onFilterSelect("all") },
    { key: "active", name: "Active", onSelect: () => onFilterSelect("active") },
    { key: "draft", name: "Draft", onSelect: () => onFilterSelect("draft") },
  ]);

  const onStartCampaignPress = useListener(() => {
    if (me) {
      navigation.navigate("CampaignCreate");
    } else {
      navigation.navigate("SignIn", { next: "campaign-create" });
    }
  });

  return (
    <MainLayout
      isMobile={isMobile}
      loading={loading && page === 1}
      loadingText="Loading Campaigns..."
      footer={
        isMobile && (
          <StickyFooter style={placeCenter}>
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.buttonMobile}
              onPress={onStartCampaignPress}
            />
          </StickyFooter>
        )
      }
      useShareDialog
      shareTitle={contextCampaign?.name || ""}
      shareQrCode={contextCampaign?.valoraQrCode || ""}
    >
      <Header isMobile={isMobile}>
        <Heading isMobile={isMobile}>My Campaigns</Heading>
        {isMobile ? (
          <TextWithHighlights customStyles={highlightedTextStyles}>
            {`You have {${count}} ${filter === "all" ? "" : `${filter} `}campaigns`}
          </TextWithHighlights>
        ) : (
          <Button
            text="Start a Campaign"
            type="greenPrimary"
            containerStyle={styles.button}
            onPress={onStartCampaignPress}
          />
        )}
      </Header>
      <Info>
        <CampaignFilterBar
          infoTemplate={
            isMobile ? "" : `You have {${count}} ${filter === "all" ? "" : `${filter} `}campaigns`
          }
          filters={filterItems}
        />
      </Info>
      {filteredCampaigns.length > 0 ? (
        <CampaignGallery
          campaigns={filteredCampaigns}
          showFundingMeters
          showDeadlines
          setCampaign={setContextCampaign}
        />
      ) : (
        <EmptyGallery>
          <NoCampaign />
          <NoCampaignText isMobile={isMobile}>
            We&rsquo;re looking forward to seeing your first campaign!
          </NoCampaignText>
          {!isMobile && (
            <Button
              text="Start a Campaign"
              type="greenPrimary"
              containerStyle={styles.noCampaignButton}
              onPress={onStartCampaignPress}
            />
          )}
        </EmptyGallery>
      )}
      {next ? (
        <Button
          text="Load more..."
          disabled={loading}
          onPress={() => setPage(page + 1)}
          containerStyle={[styles.loadMore, isMobile && styles.loadMoreMobile]}
        />
      ) : (
        <LogoContainer>
          <LogoGray width={35} height={35} />
        </LogoContainer>
      )}
    </MainLayout>
  );
};

export default observer(Dashboard);
