import * as FileSystem from "expo-file-system";
import base, { APIDetail, config } from "./base";

export interface APIUser {
  id: number;
  phoneNumber: string;
  address: string;
  isActive: boolean;
  isStaff: boolean;
  name: string;
  location: string;
  bio: string;
  timezone: string;
  photo: string | null;
  photoSizes: {
    big?: string;
    medium?: string;
  };
  facebook: string;
  twitter: string;
  linkedin: string;
  valoraQrCode: string;
}

export type APIUserInput = Partial<
  Omit<APIUser, "id" | "phoneNumber" | "address" | "isActive" | "photoSizes" | "isStaff">
>;

export interface APIUserDetail extends APIDetail<APIUser> {}
export const detail = (userId: number) => base.get<APIUserDetail>(`users/${userId}/`);

async function updateKy(input: APIUserInput, userId: number) {
  const json = { ...input };

  // If input contains the old photo, remove it from the request body.
  // Otherwise, set it to `null` so that API knows to delete the photo.
  if (json.photo) {
    delete json.photo;
  } else {
    json.photo = null;
  }

  return base.put<APIUserUpdate>(`users/${userId}/`, { json });
}

async function updateFS(input: APIUserInput, userId: number) {
  const formData = new FormData();
  formData.append("name", input.name || "");
  formData.append("location", input.location || "");
  formData.append("bio", input.bio || "");
  formData.append("timezone", input.timezone || "");

  const extension = input.photo!.substring("data:image/".length, input.photo!.indexOf(";base64"));
  const res = await fetch(input.photo!);
  const blob = await res.blob();
  formData.append("photo", blob, `photo.${extension}`);

  return base.put<APIUserUpdate>(`users/${userId}/`, { body: formData });
}

export interface APIUserUpdate extends APIDetail<APIUser> {}
export const update = (userId: number, input: APIUserInput) => {
  if (!input.photo || !input.photo.startsWith("data:image/")) {
    return updateKy(input, userId);
  }
  return updateFS(input, userId);
};

export const updateAddress = (userId: number, address: string | null) =>
  base.put<APIUserDetail>(`users/${userId}/update_address/`, { json: { address } });
