import React, { useContext } from "react";
import { ViewProps } from "react-native";
import { useNavigation } from "@react-navigation/native";
import Campaign from "../store/models/Campaign";
import CampaignPreviewCard from "./CampaignPreviewCard";
import Gallery from "./Gallery";
import ShareContext from "./contexts/ShareContext";
import useListener from "../hooks/useListener";

export interface CampaignGalleryProps extends ViewProps {
  campaigns: Campaign[];
  showFundingMeters?: boolean;
  showDeadlines?: boolean;
  setCampaign?: React.Dispatch<React.SetStateAction<Campaign | undefined>>;
}

const CampaignGallery: React.FC<CampaignGalleryProps> = ({
  campaigns,
  showFundingMeters,
  showDeadlines,
  setCampaign,
  ...props
}) => {
  const { onShareOptionPress } = useContext(ShareContext);
  const navigation = useNavigation();

  const onCampaignManagePress = useListener((campaign) => {
    navigation.navigate("CampaignEdit", { id: campaign.id });
  });

  return (
    <Gallery gap={24} {...props}>
      {campaigns.map((campaign) => (
        <CampaignPreviewCard
          key={campaign.id}
          campaign={campaign}
          showMeter={showFundingMeters}
          showDeadline={showDeadlines}
          onManagePress={onCampaignManagePress}
          onSharePress={() => {
            if (setCampaign) {
              setCampaign(campaign!);
            }
            onShareOptionPress(campaign.id!, "qrcode");
          }}
        />
      ))}
    </Gallery>
  );
};

export default CampaignGallery;
