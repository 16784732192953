import { useNavigation } from "@react-navigation/native";
import { Divider, EvaProp, Layout, withStyles, Text } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ScrollView } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import ProfileForm from "../components/forms/ProfileForm";
import LoadingOverlay from "../components/LoadingOverlay";
import SafeArea from "../components/SafeArea";
import { useStore } from "../store";
import TopNavigationResponsive from "../components/TopNavigationResponsive";
import PageFooter from "../components/PageFooter";
import RoundedButton from "../components/Button";
import useWindowSize from "../hooks/useWindowSize";
import TermsConditionsModal from "../components/TermsConditionsModal";
import handleValidateProfile from "../utils/handleValidateProfile";

interface Props {
  eva?: EvaProp;
}

const EditProfileScreen: React.FC<Props> = ({ eva }) => {
  const navigation = useNavigation();
  const store = useStore();
  const { me } = store;
  const { isMobile } = useWindowSize();

  const defaultValues = me?.asAPI;
  const methods = useForm({ defaultValues });
  const [isLoading, setIsLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);

  if (!me) {
    return null;
  }

  const handleTermsConditions = () => {
    setModalVisible(true);
  };

  return (
    <SafeArea>
      <LoadingOverlay visible={isLoading} />
      <TopNavigationResponsive />
      <ScrollView style={eva?.style?.scroller}>
        <Layout style={eva?.style?.wrapper}>
          <Layout style={eva?.style?.mainContent}>
            <Layout style={isMobile ? eva?.style?.mainMobile : eva?.style?.mainCard}>
              {/* TODO: Indicate if editing existing or creating new profile */}
              <Text style={eva?.style?.headerTitle}>Create Profile</Text>
              <Divider style={eva?.style?.miniDivider} />
              <FormProvider {...methods}>
                <ProfileForm
                  control={methods.control}
                  errors={methods.formState.errors}
                  isLoading={isLoading}
                />
              </FormProvider>
              <Divider />
              <RoundedButton
                disabled={isLoading}
                status="primary"
                onPress={methods.handleSubmit(
                  handleValidateProfile(store, navigation, methods, true, setIsLoading)
                )}
                containerStyle={eva?.style?.saveButton}
                text="Continue"
                type="greenPrimary"
              />
              <Text style={eva?.style?.agreementText}>
                By clicking continue, you are agreeing to Doni’s{"\n"}
                <TouchableOpacity onPress={handleTermsConditions}>
                  <Text style={eva?.style?.agreementLink}>Terms and Conditions</Text>
                </TouchableOpacity>
              </Text>
            </Layout>
          </Layout>
          <PageFooter />
        </Layout>
      </ScrollView>
      <TermsConditionsModal visible={modalVisible} setVisible={setModalVisible} />
    </SafeArea>
  );
};

export default withStyles(observer(EditProfileScreen), () => ({
  scroller: {
    flex: 1,
    height: "calc(100vh - 80px)",
  },
  wrapper: {
    display: "flex",
    minHeight: "calc(100vh - 81px)",
  },
  mainContent: {
    backgroundColor: "#f8f9f9",
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  saveButton: {
    height: 40,
  },
  saveButtonText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "#ffffff",
  },
  mainCard: {
    flexShrink: 1,
    width: 538,
    rowGap: 24,
    paddingHorizontal: 64,
    paddingVertical: 64,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 64,
    marginBottom: 64,
    boxShadow: "0px 4px 11px 0px #0000000F",
    border: 0,
    borderRadius: 10,
    overflow: "hidden",
  },
  mainMobile: {
    flex: 1,
    width: "100%",
    rowGap: 24,
    paddingHorizontal: 64,
    paddingVertical: 64,
  },
  headerTitle: {
    fontSize: 24,
    fontFamily: "Jost_500Medium",
  },
  miniDivider: {
    backgroundColor: "#35d07f",
    height: 4,
    width: 57,
    radius: 100,
  },
  agreementText: {
    textAlign: "center",
    fontSize: 14,
    fontFamily: "Jost_400Regular",
    color: "#757F87",
  },
  agreementLink: {
    fontSize: 14,
    fontFamily: "Jost_400Regular",
    color: "#3488EC",
  },
}));
