import React, { useEffect, useRef, useState } from "react";
import {
  Autocomplete,
  Icon,
  AutocompleteProps,
  AutocompleteItem,
  Text,
} from "@ui-kitten/components";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import { Keyboard, KeyboardEventName, Platform, StyleSheet } from "react-native";
import styled from "rn-css";
import timezones from "../../utils/timezones";

interface Props extends AutocompleteProps {
  onFormChange: (value: string) => void;
}

const styles = StyleSheet.create({
  itemText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
  },
  autoComplete: {
    minWidth: 0,
  },
});

const showEvent: KeyboardEventName = Platform.select({
  android: "keyboardDidShow",
  default: "keyboardWillShow",
});

const hideEvent: KeyboardEventName = Platform.select({
  android: "keyboardDidHide",
  default: "keyboardWillHide",
});

const StyledText = styled(Text)`
  &:hover {
    color: #27a563;
  }
`;

const TimezoneAutocomplete: React.FC<Props> = ({
  onFormChange,
  onBlur,
  value,
  textStyle,
  ...props
}) => {
  const [placement, setPlacement] = useState("bottom");

  const autocompleteRef = useRef<Autocomplete>(null);

  const timezoneMap = Object.entries(timezones.all).filter(([name]) =>
    name.toLowerCase().includes((value || "").toLowerCase())
  );

  useEffect(() => {
    const keyboardShowListener = Keyboard.addListener(showEvent, () => {
      setPlacement("top");
    });

    const keyboardHideListener = Keyboard.addListener(hideEvent, () => {
      setPlacement("bottom");
    });

    return () => {
      keyboardShowListener.remove();
      keyboardHideListener.remove();
    };
  });

  return (
    <Autocomplete
      accessoryRight={(iconProps) => (
        <TouchableWithoutFeedback onPress={autocompleteRef.current?.focus}>
          <Icon name="chevron-down" {...iconProps} />
        </TouchableWithoutFeedback>
      )}
      onBlur={onBlur}
      onSelect={(index) => onFormChange(timezoneMap[index][0])}
      onChangeText={(text) => onFormChange(text)}
      ref={autocompleteRef}
      placement={placement}
      value={value}
      textStyle={[textStyle, styles.autoComplete]}
      {...props}
    >
      {timezoneMap.map(([name, tz]) => (
        <AutocompleteItem
          key={tz}
          title={(evaProps) => (
            <StyledText {...evaProps} style={styles.itemText}>
              {name}
            </StyledText>
          )}
        />
      ))}
    </Autocomplete>
  );
};

export default TimezoneAutocomplete;
