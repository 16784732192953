import { useNavigation, useRoute } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import { Icon, Text as TextBase } from "@ui-kitten/components";
import { observer } from "mobx-react-lite";
import { Image as ImageBase, StyleSheet, View } from "react-native";
import styled from "styled-components/native";
import rnStyled from "rn-css";
import { TouchableOpacity } from "react-native-gesture-handler";
import { useStore } from "../store";
import useListener from "../hooks/useListener";
import { daysUntil } from "../utils/daysUntil";
import { FlexCol, FlexRow } from "../styles/containers";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import Button from "../components/Button";
import CreationChipBase from "../components/CreationChip";
import ProgressBarBase from "../components/ProgressBar";
import InfoTag from "../components/InfoTag";
import useCampaign from "../hooks/useCampaign";
import useWindowSize from "../hooks/useWindowSize";
import CampaignActionsMobileBase from "../components/CampaignActionsMobile";
import CampaignShareOptions from "../components/CampaignShareOptions";

const styles = StyleSheet.create({
  button: {
    width: 240,
    height: 40,
  },
  actionButton: {
    width: 140,
    height: 40,
  },
  actionButtonText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "inherit",
  },
  actionButtonIcon: {
    paddingLeft: 7,
    width: 16,
    height: 16,
    // @ts-ignore
    fill: "#27a563",
  },
  blueIcon: {
    // @ts-ignore
    fill: "#3488EC",
  },
  whiteIcon: {
    // @ts-ignore
    fill: "#ffffff",
    paddingLeft: 0,
    paddingRight: 7,
  },
  paragraph: {
    fontFamily: "Jost_500Medium",
    fontStyle: "italic",
    fontWeight: "500",
  },
});

const MainLayout = styled(MainLayoutBase)<{ isMobile: boolean }>`
  padding-bottom: ${({ isMobile }) => (isMobile ? 0 : 36)}px;
  gap: ${({ isMobile }) => (isMobile ? 24 : 36)}px;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.04);
`;

const ImageWrapper = rnStyled(View)`
  height: 368px;
  width: 100%;
  overflow: hidden;
  border-bottom: 1px solid rgb(218, 229, 235);
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Image = styled(ImageBase)`
  width: auto;
  height: 100%;
  object-fit: cover;
`;

const InfoWrapper = styled(FlexCol)`
  padding: 0px 24px;
  gap: 24px;
  max-width: 1000px;
  width: 100%;
`;

const Header = styled(FlexRow)`
  align-items: center;
  justify-content: space-between;
  gap: 24px;
`;

const Title = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: ${({ isMobile }) => (isMobile ? 24 : 32)}px;
  line-height: ${({ isMobile }) => (isMobile ? 35 : 46)}px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const ActionsWrapper = styled(FlexRow)`
  align-items: center;
  gap: 12px;
`;

const CreationChip = styled(CreationChipBase)`
  flex: auto;
`;

const MeterInfo = styled(FlexCol)`
  gap: 12px;
`;

const StatsInfo = styled(FlexRow)`
  align-items: flex-start;
  gap: 24px;
`;

const StatsLeft = styled(FlexCol)`
  flex: auto;
`;

const RaisedAmountText = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: ${({ isMobile }) => (isMobile ? 18 : 24)}px;
  line-height: ${({ isMobile }) => (isMobile ? 26 : 35)}px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #27a563;
`;

const LabelText = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ isMobile }) => (isMobile ? 14 : 16)}px;
  line-height: ${({ isMobile }) => (isMobile ? 20 : 23)}px;
  color: #757f87;
`;

const RaisedPercentText = styled(Text)<{ isMobile: boolean }>`
  font-family: "Jost_600SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: ${({ isMobile }) => (isMobile ? 16 : 18)}px;
  line-height: 24px;
  color: #27a563;
`;

const MoreInfo = styled(FlexRow)<{ isMobile: boolean }>`
  align-items: center;
  flex-wrap: wrap;
  gap: ${({ isMobile }) => (isMobile ? 8 : 24)}px;
`;

const ProgressBar = styled(ProgressBarBase)`
  height: 16px;
  background-color: #edeeef;
`;

const HorizontalRule = styled(View)`
  height: 1px;
  background-color: #edeeef;
`;

const FlexView = styled(View)`
  flex: auto;
`;

const CampaignActionsMobile = styled(CampaignActionsMobileBase)`
  position: sticky;
  bottom: 0px;
  width: 100%;
`;

interface Props extends MainLayoutProps {}

const getDaysUntilTagText = (remainingDays: number) => {
  if (Number.isNaN(remainingDays)) {
    return "No end date";
  }
  if (remainingDays < 0) {
    return "Closed";
  }
  return `${remainingDays} days to go`;
};

const CampaignScreen: React.FC<Props> = (props) => {
  const { isMobile } = useWindowSize();
  const [showSharePopup, setShowSharePopup] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [qrCodeModalVisible, setQrCodeModalVisible] = useState(false);
  const navigation = useNavigation();
  const route = useRoute();

  const { id } = (route?.params || {}) as { id: string };
  const store = useStore();
  const { campaign, loading } = useCampaign(id);
  const isOwnCampaign = !!(campaign?.organizer && campaign?.organizer === store?.me?.id);

  const onDonatePress = useListener(() => {
    if (store.me) {
      navigation.navigate("CampaignDonate", { id });
    } else {
      navigation.navigate("SignIn", { next: "campaign-donate", campaignId: id });
    }
  });
  const onSharePress = useListener(() => {
    setShowSharePopup((flag) => !flag);
  });
  const onManagePress = useListener(() => {
    navigation.navigate("CampaignEdit", { id });
  });
  const onWithdrawPress = useListener(() => {
    navigation.navigate("CampaignWithdraw", { id });
  });
  const onSharePopupBackdropPress = useListener(() => {
    setShowSharePopup(false);
  });
  const onProfileChipPress = useListener(() => {
    navigation.navigate("FundraiserDetail", { id: campaign!.organizer });
  });

  useEffect(() => {
    if (campaign) {
      navigation.setOptions({ title: `Doni | ${campaign.name}` });
    }
  }, [campaign, navigation]);

  const progress = campaign?.percentFunding || 0;
  const remainingDays = daysUntil(campaign?.endDate || "");

  return (
    <MainLayout
      isMobile={isMobile}
      alignStretch={false}
      loading={loading}
      loadingText="Loading Campaign..."
      useShareDialog
      shareTitle={campaign?.name || ""}
      shareQrCode={campaign?.valoraQrCode || ""}
      {...props}
    >
      <ImageWrapper>
        <Image source={{ uri: campaign?.cover || "" }} />
      </ImageWrapper>
      <InfoWrapper>
        <Header>
          <Title isMobile={isMobile}>{campaign?.name || ""}</Title>
          {!isMobile && !isOwnCampaign && (
            <Button
              text="Donate to Campaign"
              type="greenPrimary"
              containerStyle={styles.button}
              onPress={onDonatePress}
            />
          )}
          {!isMobile && isOwnCampaign && (
            <Button type="greenPrimary" containerStyle={styles.button} onPress={onWithdrawPress}>
              {() => (
                <>
                  <Icon
                    name="download-outline"
                    style={[styles.actionButtonIcon, styles.whiteIcon]}
                  />
                  <Text style={styles.actionButtonText}>Withdraw funds</Text>
                </>
              )}
            </Button>
          )}
        </Header>
        <ActionsWrapper>
          {campaign && (
            <FlexView>
              <TouchableOpacity onPress={onProfileChipPress}>
                <CreationChip
                  author={{
                    photo: campaign.organizerPhoto,
                    name: campaign.organizerName || "Unknown",
                  }}
                  createdAt={campaign?.created || ""}
                />
              </TouchableOpacity>
            </FlexView>
          )}
          {isOwnCampaign && !isMobile && (
            <Button
              type="blueSecondary"
              containerStyle={styles.actionButton}
              onPress={onManagePress}
            >
              {() => (
                <>
                  <Text style={[styles.actionButtonText]}>Manage</Text>
                  <Icon name="edit-outline" style={[styles.actionButtonIcon, styles.blueIcon]} />
                </>
              )}
            </Button>
          )}
          {!isMobile && (
            <CampaignShareOptions
              anchor={() => (
                <Button
                  type="greenSecondary"
                  containerStyle={styles.actionButton}
                  onPress={onSharePress}
                >
                  {() => (
                    <>
                      <Text style={styles.actionButtonText}>Share</Text>
                      <Icon name="share-outline" style={styles.actionButtonIcon} />
                    </>
                  )}
                </Button>
              )}
              campaignId={campaign?.id || ""}
              visible={showSharePopup}
              onBackdropPress={onSharePopupBackdropPress}
            />
          )}
        </ActionsWrapper>
        <MeterInfo>
          <ProgressBar progress={progress} progressFillColor="#35d07f" />
          <StatsInfo>
            <StatsLeft>
              <RaisedAmountText isMobile={isMobile}>
                cUSD {campaign?.totalReceivedDecimal || 0}
              </RaisedAmountText>
              <LabelText isMobile={isMobile}>
                raised of cUSD {campaign?.goalDecimal || 0} goal
              </LabelText>
            </StatsLeft>
            <FlexCol>
              <LabelText isMobile={isMobile}>Raised</LabelText>
              <RaisedPercentText isMobile={isMobile}>
                {(progress * 100).toFixed(2)}%
              </RaisedPercentText>
            </FlexCol>
          </StatsInfo>
        </MeterInfo>
        <HorizontalRule />
        <MoreInfo isMobile={isMobile}>
          <InfoTag iconName="clock-outline" text={getDaysUntilTagText(remainingDays)} />
          <InfoTag iconName="pricetags-outline" text={campaign?.categoryName || ""} />
          {campaign?.location && campaign.location !== "" && (
            <InfoTag iconName="pin-outline" text={campaign?.location || ""} />
          )}
        </MoreInfo>
        {/* TO-DO: Convert the Description below to use markdown for more flexibility and better maintainability */}
        <FlexCol>
          <Text>{campaign?.content || ""}</Text>
        </FlexCol>
      </InfoWrapper>
      {isMobile && (
        <CampaignActionsMobile
          onDonatePress={onDonatePress}
          campaignId={campaign?.id || ""}
          isOwnCampaign={isOwnCampaign}
          onManagePress={onManagePress}
          onWithdrawPress={onWithdrawPress}
        />
      )}
    </MainLayout>
  );
};

export default observer(CampaignScreen);
