import React, { useContext, useState } from "react";
import styled from "rn-css";
import { observer } from "mobx-react-lite";
import { useNavigation } from "@react-navigation/native";
import { useStore } from "../store";
import useListener from "../hooks/useListener";
import useWindowSize from "../hooks/useWindowSize";
import WalletConnectContext from "../components/contexts/WalletConnectContext";
import WalletConnectDialog from "../components/WalletConnectDialog";
import WalletSelectDialog from "../components/WalletSelectDialog";
import ValoraConnectDialog from "../components/ValoraConnectDialog";
import Toast, { SHORT } from "../components/Toast";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import { propPlaceCenter } from "../styles/containers";

const MainLayout = styled(MainLayoutBase)`
  ${propPlaceCenter}
  padding: 65px 0px;

  @media (max-width: 768px) {
    padding: 0px;
  }
`;

interface Props extends MainLayoutProps {}

const WalletConnectScreen: React.FC<Props> = (props) => {
  const navigation = useNavigation();
  const { isMobile } = useWindowSize();
  const { connectWallet, closeQRCodeModal, disconnectWallet } = useContext(WalletConnectContext);
  const store = useStore();
  const hasValoraWallet = !!store.address;

  const [walletSelectionVisible, setWalletSelectionVisible] = useState(false);
  const [selectedWalletName, setSelectedWalletName] = useState("");

  const onConnectWallet = useListener(() => {
    setWalletSelectionVisible(true);
  });
  const onDone = useListener(() => {
    navigation.navigate("Dashboard");
  });
  const onCloseWalletSelection = useListener(() => {
    setWalletSelectionVisible(false);
    setSelectedWalletName("");
  });
  const onSelectWallet = useListener(({ name }: { name: string }) => {
    setSelectedWalletName(name.toLowerCase());
  });
  const onGotoSelectWallet = useListener(() => {
    setSelectedWalletName("");
  });
  const onOpenValoraApp = useListener(() => {});
  const handleWalletLink = async () => {
    const { contractKit: kit, error } = await connectWallet(true, store.address);
    const address = kit?.defaultAccount;

    let type = "error";
    let message = error;

    if (address) {
      try {
        await store.updateAddress(address);
        type = "success";
        message = "Successfully linked wallet";
        navigation.navigate("Dashboard");
      } catch (e) {
        console.error(e);
        message = "Invalid address";
      }
    }

    closeQRCodeModal();
    Toast.show({
      type,
      text1: message,
      visibilityTime: SHORT,
    });
  };

  const handleWalletUnlink = async () => {
    await disconnectWallet();

    try {
      await store.updateAddress(null);
      Toast.show({
        type: "success",
        text1: "Successfully unlinked wallet",
        visibilityTime: SHORT,
      });
    } catch (e) {
      console.error(e);
      Toast.show({
        type: "error",
        text1: "An error occurred",
        visibilityTime: SHORT,
      });
    }
  };

  const modalDialog = {
    valora: (
      <ValoraConnectDialog
        isMobile={isMobile}
        onClose={onCloseWalletSelection}
        onBack={onGotoSelectWallet}
        onOpenValoraApp={onOpenValoraApp}
      />
    ),
    "": (
      <WalletSelectDialog
        isMobile={isMobile}
        onClose={onCloseWalletSelection}
        onSelectWalletPress={onSelectWallet}
        hasValoraWallet={hasValoraWallet}
      />
    ),
  }[selectedWalletName];

  return (
    <MainLayout
      modalDialog={modalDialog}
      showModalDialog={walletSelectionVisible}
      onModalBackdropPress={onCloseWalletSelection}
      {...props}
    >
      <WalletConnectDialog
        onConnectWallet={onConnectWallet}
        onDone={onDone}
        hasValoraWallet={hasValoraWallet}
        onWalletLink={handleWalletLink}
        onWalletUnlink={handleWalletUnlink}
      />
    </MainLayout>
  );
};

export default observer(WalletConnectScreen);
