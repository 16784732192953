import React, { useState } from "react";
import About from "../components/About";
import HeroCarousel from "../components/HeroCarousel";
import FeaturedCampaigns from "../components/FeaturedCampaigns";
import useWindowSize from "../hooks/useWindowSize";
import carouselImage1 from "../assets/images/carousel_image1.png";
import carouselImage2 from "../assets/images/carousel_image2.png";
import carouselImage3 from "../assets/images/carousel_image3.png";
import MainLayout, { MainLayoutProps } from "../layouts/MainLayout";
import Campaign from "../store/models/Campaign";

const carouselImages = [
  { source: carouselImage1, key: "image1" },
  { source: carouselImage2, key: "image2" },
  { source: carouselImage3, key: "image3" },
];

interface Props extends MainLayoutProps {}

const HomeScreen: React.FC<Props> = (props) => {
  const { isMobile } = useWindowSize();
  const [contextCampaign, setContextCampaign] = useState<Campaign>();

  return (
    <MainLayout
      useShareDialog
      shareTitle={contextCampaign?.name || ""}
      shareQrCode={contextCampaign?.valoraQrCode || ""}
      {...props}
    >
      <HeroCarousel images={carouselImages} isMobile={isMobile} />
      <About />
      <FeaturedCampaigns setCampaign={setContextCampaign} />
    </MainLayout>
  );
};

export default HomeScreen;
