import React, { useContext, useState } from "react";
import { StyleSheet, ViewProps } from "react-native";
import { Icon, Text } from "@ui-kitten/components";
import styled from "styled-components/native";
import { Menu, MenuOptions, MenuTrigger, renderers } from "react-native-popup-menu";
import { FlexRow } from "../styles/containers";
import CampaignShareSlideOptions from "./CampaignShareSlideInOptions";
import Button from "./Button";
import useListener from "../hooks/useListener";
import ShareContext from "./contexts/ShareContext";

const styles = StyleSheet.create({
  donateButton: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: 210,
    height: 40,
    flexShrink: 1,
    flexGrow: 1,
  },
  actionButton: {
    maxWidth: 120,
    height: 40,
    flexShrink: 1,
  },
  actionButtonText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "inherit",
  },
  actionButtonIcon: {
    paddingLeft: 7,
    width: 16,
    height: 16,
    // @ts-ignore
    fill: "#27a563",
  },
  blueIcon: {
    // @ts-ignore
    fill: "#3488EC",
  },
  whiteIcon: {
    // @ts-ignore
    fill: "#ffffff",
    paddingLeft: 0,
    paddingRight: 7,
  },
});

const Container = styled(FlexRow)`
  justify-content: space-between;
  gap: 12px;
  padding: 20px;
  background: #ffffff;
  border: 1px solid #edeeef;
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.05);
  margin-top: auto;
`;

export interface CampaignActionsMobileProps extends ViewProps {
  onDonatePress: () => void;
  isOwnCampaign: boolean;
  onManagePress: () => void;
  onWithdrawPress: () => void;
  campaignId: string | number;
}

const CampaignActionsMobile: React.FC<CampaignActionsMobileProps> = ({
  onDonatePress,
  isOwnCampaign,
  onManagePress,
  onWithdrawPress,
  campaignId,
  ...props
}) => {
  const [showShareMenu, setShowShareMenu] = useState(false);
  const { onShareOptionPress, linkCopied } = useContext(ShareContext);

  const onSharePress = useListener(() => {
    setShowShareMenu((flag) => !flag);
  });
  const onMenuClose = useListener(() => {
    setShowShareMenu(false);
  });

  return (
    <Container {...props}>
      {isOwnCampaign ? (
        <Button type="greenPrimary" containerStyle={styles.donateButton} onPress={onWithdrawPress}>
          {() => (
            <>
              <Icon name="download-outline" style={[styles.actionButtonIcon, styles.whiteIcon]} />
              <Text style={styles.actionButtonText}>Withdraw Funds</Text>
            </>
          )}
        </Button>
      ) : (
        <Button
          text="Donate to Campaign"
          type="greenPrimary"
          containerStyle={styles.donateButton}
          onPress={onDonatePress}
        />
      )}

      {isOwnCampaign ? (
        <Button type="blueSecondary" containerStyle={styles.actionButton} onPress={onManagePress}>
          {() => (
            <>
              <Text style={styles.actionButtonText}>Manage</Text>
              <Icon name="edit-outline" style={[styles.actionButtonIcon, styles.blueIcon]} />
            </>
          )}
        </Button>
      ) : (
        <Menu
          renderer={renderers.SlideInMenu}
          opened={showShareMenu}
          onSelect={(item) => onShareOptionPress(campaignId, item)}
          onBackdropPress={onMenuClose}
        >
          <MenuTrigger>
            <Button
              type="greenSecondary"
              containerStyle={styles.actionButton}
              onPress={onSharePress}
            >
              {() => (
                <>
                  <Text style={styles.actionButtonText}>Share</Text>
                  <Icon name="share-outline" style={styles.actionButtonIcon} />
                </>
              )}
            </Button>
          </MenuTrigger>
          <MenuOptions>
            <CampaignShareSlideOptions linkCopied={linkCopied} onClosePress={onMenuClose} />
          </MenuOptions>
        </Menu>
      )}
    </Container>
  );
};

export default CampaignActionsMobile;
