import React, { useEffect, useState } from "react";
import { Pressable, StyleSheet, ViewProps } from "react-native";
import styled from "rn-css";
import { Icon, Text as TextBase } from "@ui-kitten/components";
import { FlexRow, propFlexRow } from "../styles/containers";
import useWindowSize from "../hooks/useWindowSize";
import useListener from "../hooks/useListener";
import TextWithHighlights from "./TextWithHighlights";
import MenuOptions, { MenuOption } from "./MenuOptions";

const responsive = <A, B = A>(ifMobile: A, ifDesktop: B) => ({ isMobile }: { isMobile: boolean }) =>
  isMobile ? ifMobile : ifDesktop;

const styles = StyleSheet.create({
  text: {
    fontSize: 18,
    lineHeight: 26,
  },
  highlights: {
    fontSize: 18,
    lineHeight: 26,
    color: "#3488ec",
  },
});

const mobileStyles = StyleSheet.create({
  text: {
    fontSize: 14,
    lineHeight: 20,
  },
  highlights: {
    fontSize: 14,
    lineHeight: 20,
    color: "#3488ec",
  },
});

const DropdownWrapper = styled(Pressable)<{ isMobile: boolean }>`
  ${propFlexRow};
  align-items: center;
  justify-content: center;
  gap: 12px;
  padding: 8px 0px;
  border-radius: 999999px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: rgba(52, 136, 236, 0.2);
    padding: 8px 12px;
  }
`;

const Text = styled(TextBase)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 26px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const DropdownIcon = styled(Icon)`
  width: 16px;
  height: 16px;
  fill: #115fbb;
  transform: rotate(-90deg);
`;

const FiltersLabel = styled(Text)<{ isMobile: boolean }>`
  text-transform: capitalize;
  font-family: "Jost_500Medium";
  font-weight: 500;
  font-size: ${responsive(14, 16)}px;
  line-height: ${responsive(20, 23)}px;
  display: flex;
  align-items: center;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #115fbb;
`;

const Container = styled(FlexRow)`
  align-items: center;
  gap: 16px;
`;

export type CampaignFilter = MenuOption;

export interface CampaignFilterBarProps extends ViewProps {
  infoTemplate: string;
  filters?: CampaignFilter[];
}

const CampaignFilterBar: React.FC<CampaignFilterBarProps> = ({
  infoTemplate,
  filters = [],
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const [currentFilter, setCurrentFilter] = useState<CampaignFilter>();
  const [menuOpenFlag, setMenuOpenFlag] = useState(false);
  const filter = currentFilter?.name ?? "";

  const onDropdownPress = useListener(() => {
    setMenuOpenFlag((flag) => !flag);
  });

  const onFilterSelect = useListener((selectedKey: string) => {
    const selected = filters.find(({ key }) => key === selectedKey);
    const success = selected?.onSelect?.(selectedKey) ?? true;

    if (success) {
      setCurrentFilter(selected);
      setMenuOpenFlag(false);
    }
  });

  const onMenuBackdropPress = useListener(() => {
    setMenuOpenFlag(false);
  });

  useEffect(() => {
    setCurrentFilter(filters[0]);
  }, [filters]);

  return (
    <Container {...props}>
      {infoTemplate && (
        <TextWithHighlights customStyles={isMobile ? mobileStyles : styles}>
          {infoTemplate}
        </TextWithHighlights>
      )}
      <MenuOptions
        anchor={() => (
          <DropdownWrapper isMobile={isMobile} onPress={onDropdownPress}>
            <FiltersLabel isMobile={isMobile}>{filter}</FiltersLabel>
            <DropdownIcon name="arrow-ios-back" />
          </DropdownWrapper>
        )}
        options={filters}
        visible={menuOpenFlag}
        onSelect={onFilterSelect}
        onBackdropPress={onMenuBackdropPress}
      />
    </Container>
  );
};

export default CampaignFilterBar;
