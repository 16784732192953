import React, { useMemo, useState } from "react";
import { Image, Pressable, PressableProps, Text } from "react-native";
import { Divider, Icon as IconBase } from "@ui-kitten/components";
import { useNavigation } from "@react-navigation/native";
import styled from "styled-components/native";
import { observer } from "mobx-react-lite";
import { useStore } from "../store";
import { responsive } from "../utils/responsive";
import { daysUntil } from "../utils/daysUntil";
import { FlexCol, FlexRow, propFlexCol, propPlaceCenter } from "../styles/containers";
import Campaign from "../store/models/Campaign";
import useListener from "../hooks/useListener";
import PressableIcon from "./PressableIcon";
import ProgressBarBase from "./ProgressBar";
import useWindowSize from "../hooks/useWindowSize";
import MenuOptionsBase from "./MenuOptions";

const menuStyle = {
  optionItem: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 17,
    paddingRight: 17,
  },
  optionText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 15,
    lineHeight: 22,
    color: "#2e3338",
  },
};

const mobileMenuStyle = {
  optionItem: {
    paddingTop: 13,
    paddingBottom: 13,
    paddingLeft: 17,
    paddingRight: 17,
  },
  optionText: {
    fontFamily: "Jost_400Regular",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: "#2e3338",
  },
};

const Icon = styled(IconBase)`
  width: 12px;
  height: 12px;
  fill: #231f20;
`;

const ProgressBar = styled(ProgressBarBase)`
  height: 8px;
  background-color: #edeeef;
`;

const ActionIcon = styled(PressableIcon)`
  width: 24px;
  height: 24px;
`;

const Tag = styled(FlexRow)<{ isEnded?: boolean }>`
  ${propPlaceCenter};
  gap: 5px;
  padding: 2px 12px;
  position: absolute;
  z-index: 1;
  right: 11px;
  top: 11px;
  background: ${({ isEnded = false }) =>
    isEnded ? "rgba(210, 90, 74, 0.6)" : "rgba(255, 255, 255, 0.6)"};
  backdrop-filter: blur(6px);
  border-radius: 4px;
`;

const TagText = styled(Text)<{ isEnded?: boolean }>`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ isEnded = false }) => (isEnded ? "#ffffff" : "#2e3338")};
`;

const TagSemiBoldText = styled(TagText)`
  font-family: "Jost_600SemiBold";
  font-weight: 600;
`;

const TagBoldText = styled(TagText)`
  font-family: "Jost_800ExtraBold";
  font-weight: 800;
`;

const ClockIcon = styled(Icon)<{ $isEnded?: boolean }>`
  width: 10px;
  height: 10px;
  fill: ${({ $isEnded = false }) => ($isEnded ? "#ffffff" : "#2e3338")};
`;

const PreviewImage = styled(Image)`
  max-width: 100%;
  width: auto;
  height: 190px;
`;

const PreviewInfo = styled(FlexCol)`
  gap: 8px;
  padding: 19px 16px;
  flex-grow: 0;
`;

const PreviewHeader = styled(FlexRow)`
  gap: 4px;
`;

const PreviewHeading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  display: flex;
  align-items: center;
  color: #2e3338;
  flex: auto;
`;

const TrimmedText = styled(Text).attrs((props) => ({ numberOfLines: 2, ...props }))`
  height: 48px;
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
  align-self: stretch;
  flex-grow: 0;
`;

const PreviewFooter = styled(FlexRow)`
  align-items: center;
  gap: 18px;
`;

const FundedInfo = styled(Text)`
  font-family: "Jost_600SemiBold";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #27a563;
  flex-grow: 0;
`;

const MenuOptions = styled(MenuOptionsBase)`
  padding: 0px;
  border: 1px solid #edeeef;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
`;

const Container = styled(Pressable)<{ isMobile: boolean }>`
  ${propFlexCol};
  position: relative;
  justify-content: center;
  padding: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(229, 229, 229, 0.5);
  border-radius: 4px;
  flex-grow: 1;
  overflow: hidden;
  cursor: pointer;
  ${responsive("width: 100%;", "")}
`;

export interface CampaignPreviewCardProps extends PressableProps {
  campaign: Campaign;
  showMeter?: boolean;
  showDeadline?: boolean;
  onManagePress?: (campaign: Campaign) => void;
  onSharePress?: (campaign: Campaign) => void;
}

const CampaignPreviewCard: React.FC<CampaignPreviewCardProps> = ({
  campaign,
  showMeter = false,
  showDeadline = true,
  onManagePress,
  onSharePress,
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const { token, me } = useStore();
  const navigation = useNavigation();
  const [showActionsPopup, setShowActionsPopup] = useState(false);

  const onCardPress = useListener(() => {
    navigation.navigate("CampaignDetail", { id: campaign.id });
  });

  const onActionPress = useListener(() => {
    setShowActionsPopup(false);
  });

  const onActionsPress = useListener(() => {
    setShowActionsPopup((flag) => !flag);
  });

  const onActionsBackdropPress = useListener(() => {
    setShowActionsPopup(false);
  });

  const isSignedIn = !!token && !!me;
  const isUserOwner = me?.id === campaign.organizer;
  const daysToGo = daysUntil(campaign.endDate ?? "");
  const isEnded = showDeadline && daysToGo < 0;

  const filters = useMemo(
    () =>
      isSignedIn && isUserOwner
        ? [
            { key: "manage", name: "Manage Campaign", onSelect: () => onManagePress?.(campaign) },
            { key: "share", name: "Share", onSelect: () => onSharePress?.(campaign) },
          ]
        : [{ key: "share", name: "Share", onSelect: () => onSharePress?.(campaign) }],
    [campaign, isSignedIn, isUserOwner, onManagePress, onSharePress]
  );

  return (
    <Container onPress={onCardPress} isMobile={isMobile} {...props}>
      {(!Number.isNaN(daysToGo) || !showDeadline) && (
        <Tag isEnded={isEnded}>
          {showDeadline && (
            <>
              <ClockIcon name="clock-outline" $isEnded={isEnded} />
              <TagText isEnded={isEnded}>
                <TagBoldText isEnded={isEnded}>{isEnded ? "Closed" : daysToGo}</TagBoldText>
                {!isEnded && " days to go"}
              </TagText>
            </>
          )}
          {!showDeadline && <TagSemiBoldText>{campaign.categoryName}</TagSemiBoldText>}
        </Tag>
      )}
      <PreviewImage source={{ uri: campaign.cover as string }} />
      <Divider />
      <PreviewInfo>
        <PreviewHeader>
          <PreviewHeading>{campaign.name}</PreviewHeading>
          <MenuOptions
            anchor={() => (
              <ActionIcon iconName="more-horizontal" fill="#abadaf" onPress={onActionsPress} />
            )}
            options={filters}
            visible={showActionsPopup}
            onSelect={onActionPress}
            onBackdropPress={onActionsBackdropPress}
            styleTree={isMobile ? mobileMenuStyle : menuStyle}
          />
        </PreviewHeader>
        {showMeter ? (
          <ProgressBar progress={campaign.percentFunding} progressFillColor="#35d07f" />
        ) : (
          <TrimmedText>{campaign.content}</TrimmedText>
        )}
        <PreviewFooter>
          <FundedInfo>{Math.floor(campaign.percentFunding * 100)}% Funded</FundedInfo>
          <FundedInfo>cUSD {campaign.totalReceivedDecimal}</FundedInfo>
        </PreviewFooter>
      </PreviewInfo>
    </Container>
  );
};

export default observer(CampaignPreviewCard);
