import appConfig from "../configs/appConfig";

interface ObjectKeys {
  [key: string]: string;
}

interface ShareURLList extends ObjectKeys {
  twitter: string;
  facebook: string;
  whatsapp: string;
  linkedin: string;
  email: string;
}

// https://dev.to/shahednasser/how-to-easily-add-share-links-for-each-social-media-platform-1l4f
const createShareUrl = (url: string) => {
  return {
    twitter: `https://twitter.com/intent/tweet?&url=${url}`,
    facebook: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
    whatsapp: `https://wa.me/?text=${url}`,
    linkedin: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
    email: `mailto:?body=${url}`,
  } as ShareURLList;
};

const share = (id: number | string) => {
  const baseReactUrl = appConfig.reactUrl! as string;
  const campaignUrl = `${encodeURI(baseReactUrl)}/campaigns/${id}`;
  const fundraiserUrl = `${encodeURI(baseReactUrl)}/fundraisers/${id}`;

  return {
    campaign: createShareUrl(campaignUrl),
    fundraiser: createShareUrl(fundraiserUrl),
  };
};

export default share;
