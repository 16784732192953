import React, { useState } from "react";
import { Image, StyleSheet, TouchableOpacity, View, ViewProps } from "react-native";
import { EvaProp, Input, Text, withStyles } from "@ui-kitten/components";
import styled from "rn-css";
import Button from "./Button";
import useListener from "../hooks/useListener";
import useWindowSize from "../hooks/useWindowSize";
import WalletCardBase from "./WalletCard";
import CeloWalletWhite from "./svgs/CeloWalletWhite";
import ValoraWallet from "../assets/valora_wallet.png";

const styles = StyleSheet.create({
  button: {
    height: 40,
  },
  containerMobile: {
    paddingTop: 48,
    paddingBottom: 48,
    paddingLeft: 20,
    paddingRight: 20,
    width: "100%",
    minHeight: "100%",
    borderRadius: 0,
  },
  walletInput: {
    borderWidth: 0,
  },
  walletInputText: {
    fontFamily: "Jost_500Medium",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: 15,
    lineHeight: 22,
    letterSpacing: 0.02,
    color: "#abadaf",
  },
});

const WalletCard = styled(WalletCardBase)`
  background-color: #f8f9f9;
  border-radius: 10px;
`;

const Container = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 64px;
  gap: 24px;
  width: 538px;
  background: #ffffff;
  box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.06);
  border-radius: 10px;
`;

const Heading = styled(Text)`
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #2e3338;
`;

const Subheading = styled(Text)`
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const GreenDivider = styled(View)`
  align-self: flex-start;
  width: 57px;
  height: 4px;
  max-width: 57px;
  background: #35d07f;
  border-radius: 100px;
`;

const LinkedWalletsList = styled(View)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  gap: 12px;
`;

const WalletInput = styled(Input)`
  padding: 0px;
  font-family: "Jost_500Medium";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.02em;
  color: #abadaf;
  background: #f8f9f9;
  border-radius: 10px;
  border-color: transparent;
`;

const Note = styled(Text)`
  height: 60px;
  font-family: "Jost_400Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-feature-settings: "pnum" on, "lnum" on;
  color: #757f87;
`;

const HorizontalRule = styled(View)`
  height: 1px;
  background: #edeeef;
`;

const AddWalletButton = styled(TouchableOpacity)`
  background: #f8f9f9;
  border-radius: 10px;
  padding: 16px;
  user-select: none;
  cursor: pointer;

  &:hover {
    background: #edeeef;
  }
`;

const AddWalletText = styled(Text)`
  color: #abadaf;
  font-family: Jost_500Medium;
`;

interface Props extends ViewProps {
  onConnectWallet: (value: string) => void;
  onWalletLink: () => Promise<void>;
  onWalletUnlink: () => Promise<void>;
  onDone: (value: string) => void;
  eva?: EvaProp;
  hasValoraWallet?: boolean;
}

const WalletConnectDialog: React.FC<Props> = ({
  onConnectWallet,
  onWalletLink,
  onWalletUnlink,
  onDone,
  style,
  eva,
  hasValoraWallet = false,
  ...props
}) => {
  const { isMobile } = useWindowSize();
  const [value, setValue] = useState("");

  const onDonePress = useListener(() => onDone?.(value));

  const linkedWallets = [
    ...(hasValoraWallet
      ? [
          {
            icon: <Image source={ValoraWallet} />,
            name: "Valora",
            caption: "Connect to Valora, a mobile payment app that works worldwide",
          },
        ]
      : []),
    // {
    //   icon: <CeloWallet />,
    //   name: "Celo Wallet",
    //   caption: "Connect to a Celo Wallet for web or desktop",
    // },
  ];

  return (
    <Container style={[style, isMobile && styles.containerMobile]} {...props}>
      <Heading>Connect a Wallet</Heading>
      <Subheading>
        (Optional) Connect to a Celo Wallet to withdraw and donate funds on campaigns. This step can
        be done later in settings but will be necessary in order to give and recieve funds.
      </Subheading>
      <GreenDivider />
      {linkedWallets && linkedWallets.length > 0 && (
        <LinkedWalletsList>
          {linkedWallets.map(({ icon, name }) => (
            <WalletCard
              key={name}
              icon={icon}
              name={name}
              status="linked"
              onUnlinkPress={onWalletUnlink}
            />
          ))}
        </LinkedWalletsList>
      )}
      {!hasValoraWallet && (
        <AddWalletButton onPress={onWalletLink}>
          <AddWalletText>Add Wallet</AddWalletText>
        </AddWalletButton>
      )}
      <Note>
        You must connect an authorized wallet to use Celo Dollars. Celo Dollars are digital value
        that you can pay, save and send using Valora.
      </Note>
      <Button
        onPress={onWalletLink}
        type="bluePrimary"
        containerStyle={styles.button}
        disabled={hasValoraWallet}
      >
        {(evaProps) => (
          <>
            <CeloWalletWhite width={20} height={20} />
            <Text {...evaProps} style={eva?.style?.connectWalletText}>
              Connect Wallet
            </Text>
          </>
        )}
      </Button>
      <HorizontalRule />
      <Button
        text="Done"
        onPress={onDonePress}
        containerStyle={styles.button}
        disabled={!hasValoraWallet}
      />
    </Container>
  );
};

export default withStyles(WalletConnectDialog, (theme) => ({
  connectWalletText: {
    fontSize: 15,
    fontFamily: "Jost_500Medium",
    color: "inherit",
    marginLeft: 12,
  },
}));
