import React from "react";
import moment from "moment";
import { View } from "react-native";
import styled from "rn-css";
import Accordion from "../components/Accordion";
import Markdown from "../components/Markdown";
import LogoWithText from "../components/svgs/LogoWithText";
import MainLayoutBase, { MainLayoutProps } from "../layouts/MainLayout";
import { FlexCol } from "../styles/containers";
import privacyPolicy from "../assets/data/privacy-policy.json";

const LogoWrapper = styled(View)`
  align-self: center;
`;

const HorizontalRule = styled(View)`
  height: 1px;
  background: #dddddd;
`;

const Body = styled(FlexCol)`
  gap: 48px;

  @media (max-width: 768px) {
    gap: 24px;
  }
`;

const MainLayout = styled(MainLayoutBase)`
  align-self: center;
  width: 100%;
  max-width: 818px;
  padding: 90px 20px;

  @media (max-width: 768px) {
    padding: 58px 20px;
  }
`;

interface Props extends MainLayoutProps {}

const PrivacyPolicyScreen: React.FC<Props> = (props) => {
  return (
    <MainLayout alignStretch={false} {...props}>
      <Body>
        <LogoWrapper>
          <LogoWithText />
        </LogoWrapper>

        {privacyPolicy.articles.map(({ title, body }, index) => (
          <Accordion
            key={title}
            headerComponent={<Markdown>{title}</Markdown>}
            collapsible={index > 0}
          >
            <Markdown>{body}</Markdown>
          </Accordion>
        ))}

        <HorizontalRule />

        <Markdown>
          {`*Last updated on ${moment(privacyPolicy.updatedAt, "MM-DD-YYYY").format(
            "MMMM DD, YYYY"
          )}*`}
        </Markdown>
      </Body>
    </MainLayout>
  );
};

export default PrivacyPolicyScreen;
